@import "~bootstrap/scss/bootstrap";
@import './config.scss'; //importa o arquivo de paleta de cores
@import './login.scss'; //importa o estilo da página de login
@import './cadastro.scss'; //importa o estilo da página de cadastro
@import './home.scss'; //importa estilos da página home
@import './loja.scss'; //importa estilos da página loja
@import './comprarMoedas.scss'; //importa estilos da página de compra de moedas
@import './modalPagamento.scss'; // estilos do modal de pagamento
@import './perfil.scss'; //estilos da página de perfil
@import './gerenciador.scss';


/* caso importemos uma fonte especifica
@font-face {
    font-family: 'nome da fonte';
    font-style; normal;
    font-weight: normal;
    src: url('../assets/fonts/nomedafonte.otf') format('opentype');
} */

.mensagemValidacao {
    display: block;
    color: $warningColor;
    font-size: 90%;
    margin: 0;
    margin-left: 10%;
}

html {
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#root {
    height: 100%;
    min-height: 610px;
    display: flex;
    flex: 1 1 0%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0;
}

.container-generic {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}


.header {
    background-color: $primaryColor;
    width: 100%;

    .navContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .navigation-default {
            display: none;
        }

        .headerRight-default {
            display: none;
        }

        .header-logo {
            width: 68px;
            margin: 1.5%;
        }

        .mobile-menu {
            width: 50px;
            height: 50px;
            margin: 1.5%;
            margin-top: 4%;
        }


    }

    .burger-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5%;
        margin-top: 6%;
        height: 100vh;

        .navigation-burger {
            ul {
                padding: 0;

                li {
                    padding: 0;
                    list-style-type: none;
                    margin-top: 2%;

                    h2 {
                        color: white;
                        font-size: 40px;
                    }
                }
            }
        }

        .headerRight-burger {

            .nameMoney {
                color: white;

                p {
                    color: white;
                    font-size: 40px;
                    font-weight: bold;
                }
            }

            .sair {

                position: absolute;
                bottom: 40px;

                p {
                    color: white;
                    margin: 0;
                    font-size: 25px;
                }

                img {
                    margin-top: 50%;
                    width: 60px;
                }
            }
        }

    }
}




@media screen and (min-width:800px) {


    .mobile-menu {
        display: none;
    }

    .mensagemValidacao {
        display: block;
        color: $warningColor;
        font-size: 90%;
        margin: 0;
        margin-left: 10%;
    }

    html {
        height: 100%;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    body {
        height: 100%;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    #root {
        height: 100%;
        min-height: 610px;
        display: flex;
        flex: 1 1 0%;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px;
    }

    .container-generic {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .header>div {
        display: flex;
    }

    .header {
        display: flex;
        background-color: $primaryColor;
        width: 100%;
        height: 9.96%;
        top: 0;
        justify-content: start;
        .navContainer {
            display: flex;
            width: 100%;
            justify-content: flex-start;

            .header-logo{
                height: 58px;
                width: 58px;
                margin: 3px;
            }

            .navigation-default {
                display: unset;
                display: flex;
                align-items: center;
            }


            .navigation-default>ul {
                display: flex;
                flex-direction: row;
                list-style-type: none;
                margin: 0px;
                padding: 0px;
            }

            .headerRight-default {
                display: flex;
                flex-direction: row;
                margin-right: 10px;
                height: 60%;
                align-self: center;
                position: absolute;
                right: 10px;
            }

            .headerRight-default p {
                color: white;
                margin: 0px;
                font-weight: normal;
            }

        }
    }

    .headerRight-default #username:hover{
        font-weight: bold;
    }

    .navigation-default a h2 {
        color: white;
        font-size: 1.5em;
        margin: 0px;
        white-space: nowrap;
    }

    .navigation-default li {
        display: flex;
        margin-right: 20px;
        align-items: center;
    }

    .navigation-default li a {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 10px;
        text-decoration: none;
    }

    .navigation-default li h2:hover,
    .navigation-default li h2.selected {
        font-weight: bold;
    }

    .headerRight-default p:hover,
    .headerRight-default p.selected {
        font-weight: bold;
    }

    #minhasMoedas:hover {
        font-weight: normal;
    }



    #username {
        font-size: 1.2em;
    }

    .nameMoney {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .sair {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        align-items: center;
        cursor: pointer
    }

    .sair img {
        height: 25px;
    }

    .coin {
        height: 2em;
    }

    img {
        margin: 1%;
    }

    canvas:not([id=defaultCanvas0]) {
        display: none;

    }
}