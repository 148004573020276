@import './config.scss';

h1 {
    font-size: 32px;
    font-weight: bold;
}

.usuario {
    width: 96%;
    margin-top: 2.5%;
    border: solid black 3px;
    border-radius: 10px;
    padding: 2%;
    margin: 2%;
    border-color: $darkGreenColor;

    .container-botao {
        margin: 2%;

        .containerSenhas {
            margin-top: 5%;
        }

        input {
            margin-bottom: 1%;
            height: 40px;
        }

        button {
            height: 50px; //
            margin-top: 5%; //
        }
    }
}

.container-skins {

    margin: 4%;
    margin-top: 6%;

    .bloco-skins {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 5%;
        margin-left: 15%;
    }

    .skin {
        display: flex;
        flex-direction: column;
        border: solid 1px #CCC;
        border-radius: 10px;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
        align-items: center;
        margin: 10px 20px;

        img {
            margin: 15px;
            width: 154px;
            height: 154px;
        }

        h4 {
            text-align: center;
        }
    }
}

.botao-equipar {
    margin: 10px 30px;
    background-color: $primaryColor;
    border: solid 1px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    padding: 5px 25px;
}

button {

    background-color: $primaryColor;
    border: solid 1px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    padding: 5px 25px;

}

label {
    margin: 0px;
    font-weight: bold;
}


input {
    font-size: 15px;
    display: flex;
    letter-spacing: 0.03em;
    color: $primaryColor;
    border-radius: 0%;
    margin: 3px;
    border-radius: 6px;
    //border-top-width: 0px;
    //border-right-width: 0px;
    //border-left-width: 0px;
    border-bottom: solid black 2px;
    outline: none;

    &:focus {
        border-color: $primaryColor;
    }

    &::placeholder {
        color: $lightGreyColor;
    }
}



@media screen and (min-width:990px) {


    .container-perfil {

        /* display: flex;
        flex-direction: row;
        flex: start;
        flex-wrap: wrap;
        margin-top: 2%; */
        .usuario {
            margin: 0;
            margin-top: 5%;
        }

        .container-skins {
            margin-top: 3%;

            h3 {
                margin: 0%;
                margin-bottom: 2%;
            }

            h4 {
                margin-left: 0%;
            }
        }

    }

   

    .skin {
        border: solid 1px #CCC;
        border-radius: 10px;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
        margin: 0px;
        //padding: 2.5%;
        margin: 10px 20px;

        img {
            margin: 15px
        }
    }

    .container-perfil {
        display: flex;
        height: 100%;
        flex-direction: column;
        margin: 0px 100px;

        .bloco-skins {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 5%;
            flex: start;
            align-items: none;
            //justify-content: space-between;
            padding: 0px;
            margin-left: 0;
        }
    }

    .usuario {
        .container-botao {
            display: flex;
            align-items: center;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 40px;

            .containerSenhas {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                margin-bottom: 1.5%;
            }

            button {
                margin: 0px;
                margin-top: 0%;
                height: auto;
                margin-left: 10px;
            }
        }
    }

    .containerNome {
        display: flex;
        align-items: center;
        margin-right: 40px
    }

    
    input {
        margin-left: 10px;
        margin-bottom: 0;
        margin-top: 0;
        height: 50%;
    }
}