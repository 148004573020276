@import './config.scss';

Table {
    width: 100%;

    td {
        font-size: 2em;
        font-weight: bold;
    }
}

.body {
    display: flex;
    flex-direction: column;
    height: 90.04%;
}

.body img {
    margin: 0px;
    height: 100%;
}



.salaCriada {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-wrap: wrap;
    position: relative;
    top: -13%;

}

.optPlaying {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //margin-top: 5%;
    position: absolute;
    top: 0.8%;
    //top: 12.5%;
    border: solid $darkGreenColor 3px;
    width: 60%;
    height: 70px;
    border-radius: 6px;
    color: white;
    padding: 1%;
    background-color: $primaryColor;
    //margin-bottom: 15%;
    //font-size: 20px;
    display: flex;
    align-items: center;
    //box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);

    .container-placar {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 50%;
    }

    h2 {
        font-size: 0.9em;

    }

    h1 {
        font-size: 1.8em;
        margin: 0;
    }


    .game-stats {

        font-size: 0.8em;
        text-align: center;
        font-weight: bold;
        width: 60%;
        //line-height: 1.2;

        .jogador-animal {
            align-items: center;
            font-size: 0.9em;
        }

        .timerDiv {
            //display: flex;
            //flex-direction: row;
            align-items: center;
            justify-items: center;
            font-size: 1.5em;
            margin-left: 10%;
            //margin-bottom: 10%;

            img {
                width: 20px;
                margin-right: 7%;
                margin-bottom: 4%;
            }
        }

        .turno {
            //color: $darkGreenColor;
            padding: 0;
            font-size: 0.9em;
        }
    }
}


.optArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 30vh;
    //margin-top: 20vh;

    .salaOpts {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 90vh;
        width: 90%;
        margin-top: 10%;

        th {
            font-size: 15px;
        }

        td {
            font-size: 16px;
            padding: 2%;
        }

        //border: solid 5px;
        button {
            width: 75%;
            height: 70px;
            font-size: 16px;
            padding: 3%;
        }
    }

    .criarSala {
        display: flex;
        flex-direction: column;
        padding: 0px;
        //border: solid 5px;
        height: 50%;
        width: 90%;
        justify-content: space-around;
        align-items: center;

        button {
            margin-top: 0%;
            margin-bottom: 18%;
        }

        .criarSalaRadio {
            display: flex;
            width: 100%;
            justify-content: space-around;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 200%;
                margin-right: 8%;

                input {
                    margin-right: 5%;
                }
            }


        }


    }

    .tabuleiroArea {
        width: 60%;
    }

    .body button {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: white;
        border-radius: 12px;
        font-size: 25px;
        //height: 30%;
        width: 60%;
    }


    button {
        width: 70%;
    }
}



.tabuleiroArea img {
    display: block;
    margin: 0 auto;
    //height: 50%;
    //width: 1350px;
}

.salaCriada button {
    font-size: 25px;
    text-align: center;
    width: 70%;
}

button.tbButton {
    width: 60%;
}

input[type="radio"] {
    transform: scale(1.5);
}

@media screen and (min-width:800px) {
    Table {
        width: 100%;

        td {
            font-size: 2em;
            font-weight: bold;
        }
    }

    .body {
        display: flex;
        flex-direction: row;
        height: 90.04%;
    }

    .body img {
        margin: 0px;
        height: 100%;
    }

    .salaOpts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 70%;
        width: 90%;
        //border: solid 5px;
    }

    .salaCriada {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-wrap: wrap;
    }

    .optArea {
        .optPlaying {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            //margin-top: 5%;
            border: none;
            position: relative;
            top: 0;
            width: 400px;
            height: 300px;
            border-radius: 8px;
            //color: white;
            padding: 8%;
            background-color: white;
            margin-bottom: 15%;
            font-size: 20px;
            box-shadow: 0;

            h2 {
                font-size: 1.8em;
            }

            .container-placar {
                color: #212529;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: center;
                border-top: solid $yellowColor 5px;
                border-bottom: solid $yellowColor 5px;
            }

            .game-stats {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                background-color: $primaryColor;
                border: solid $darkGreenColor 2px;
                border-radius: 5px;
                padding: 3%;
                width: 400px;
                height: 80px;
                position: relative;
                top: -50%;

                .jogador-animal{
                    font-size: 18px;
                }

                .turno {
                    //color: $darkGreenColor;
                    font-size: 18px;
                }

                .timerDiv {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.8em;
                    margin-bottom: 0%;
                    margin-left: 0%;

                    img {
                        height: 20%;
                        margin-right: 15%;
                        margin-bottom: 0%;
                    }

                }
            }
        }
    }

    .optPlaying h1 {
        font-size: 100px;
    }

    .optArea {
        display: flex;
        width: 40%;
        justify-content: center;
        align-items: center;

    }

    .tabuleiroArea {
        width: 60%;
    }

    .body button {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: white;
        border-radius: 12px;
        font-size: 25px;
        width: 5em;
        height: 20%;
    }

    .criarSala {
        display: flex;
        flex-direction: column;
        padding: 0px;
        //border: solid 5px;
        height: 50%;
        width: 50%;
        justify-content: center;
        align-items: center;

        button {
            width: 70%;
        }
    }

    .criarSalaRadio {
        display: flex;
        width: 100%;
        justify-content: space-around;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 200%;
        }
    }

    .tabuleiroArea img {
        display: block;
        margin: 0 auto;
        //height: 50%;
        //width: 1350px;
    }

    .salaCriada button {
        font-size: 25px;
        text-align: center;
        width: 60%;
    }

    button.tbButton {
        width: 60%;
    }

    input[type="radio"] {
        transform: scale(1.5);
    }
}