@import './config.scss';

.container-pagamento{

    width: 100%;

    .container-form{
        display: flex;
        flex-direction: column;
        flex: start;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-around;

        label{
            font-size: 20px;
            margin-top: 3%;
            margin-bottom: 0;
        }

        input{
            border-style: solid;
            border-width: 1px;
            padding: 2%;
            font-size: 16px;
            letter-spacing: 0.03em;
            color: $primaryColor;
            width: 100%;
            height: 45px;
            font-size: 20px;
            border-radius: 3px;

            border-bottom: solid black 1px;

            outline:none;

        }
      
    }

    .btn-comprar{
        background-color: $primaryColor;
        color: white;
        border: solid 1px;
        border-radius: 5px;
        font-weight: bold;
        padding: 5px 25px;
        height: 60px;
    }

    .btn-cancelar{
        background-color: $warningColor;
        color: white;
        border: solid 1px;
        border-radius: 5px;
        font-weight: bold;
        padding: 5px 25px;
        height: 60px;
    }

    h5{
        margin-top: 10%;
        margin-left: 2%;
    }

    h4{
       margin-top: 3%;
       margin-left: 2%;
    }
    
}

.pagamento-sucesso{

    width: 100%;
    display: flex;
    flex-direction: row;
    flex: start;
    flex-wrap: wrap;
    align-content: center;


    .btn-fechar{
        background-color: $primaryColor;
        color: white;
        border: solid 1px;
        border-radius: 5px;
        font-weight: bold;
        padding: 5px 25px;
        height: 60px;
        }
    
    img{
        width: 15%;
    }
}




@media screen and (min-width:990px){

    .container-pagamento{

        width: 100%;

        .container-form{
            display: flex;
            flex-direction: row;
            flex: start;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-around;

            .parcela{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 183px;
                label{
                    //margin-top: 3%;
                    //margin-left: 0%;
                }
            }

    
            input{
                border-style: solid;
                border-width: 1px;
                padding: 2%;
                font-size: 16px;
                letter-spacing: 0.03em;
                color: $primaryColor;
                width: 100%;
                border-radius: 3px;
                margin: 2%;
    
                border-bottom: solid black 1px;
    
                outline:none;
    
            }
          
        }
    
        .btn-comprar{
            background-color: $primaryColor;
            color: white;
            border: solid 1px;
            border-radius: 5px;
            font-weight: bold;
            padding: 5px 25px;
            height: auto;
        }
    
        .btn-cancelar{
            background-color: $warningColor;
            color: white;
            border: solid 1px;
            border-radius: 5px;
            font-weight: bold;
            padding: 5px 25px;
            height: auto;
        }
    
        h5{
            margin-top: 10%;
            margin-left: 2%;
        }
    
        h4{
           margin-top: 3%;
           margin-left: 2%;
        }
        
    }
    
    .pagamento-sucesso{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: start;
        flex-wrap: wrap;
        align-content: center;
    
    
        .btn-fechar{
            background-color: $primaryColor;
            color: white;
            border: solid 1px;
            border-radius: 5px;
            font-weight: bold;
            padding: 5px 25px;
            height: auto;
            }
        
        img{
            width: 15%;
        }
    }
    
}