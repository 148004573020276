@import './config.scss';

@media screen and (min-width:990px) {
    .container-generic {
        display: flex;
        flex-direction: column;
    }
    
    .container-gerenciador {
        display: flex;
        height: 100%;
        flex-direction: column;
        margin: 0px 100px;
        margin-top: 2%;

        .gerenciar-sub {
            margin-bottom: 4%;
            padding-bottom: 3%;
            border-bottom: 0.2em solid ;
            border-image: linear-gradient(0.25turn, #28313B, #485461);
            border-image-slice: 1;

            button {
                margin-bottom: 1em;
            }

            h1{
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 2%;
            }

            Table{

                .actions{
                    text-align: center;
                }

                td {
                    font-size: 1em;
                    font-weight: normal;
                    
                    img{
                        height: 7rem;
                    }

                    button.delete {
                        background-color: red;
                    }
                }
            }
        }
        
    }

    .modalBody {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .vigSeason{
            display: flex;
            flex-direction: row;

            .seasonDate{ 
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }

        .dropDown {
            margin-bottom: 2%;
        }

        #selectPrior ,#selectValor {
            width: 40%;
            margin-top: 7%;
        }

        Input {
            margin-bottom: 1em;
        }

        input {
            margin-bottom: 1em;
        }

        #tipoSkin {
            margin-bottom: .3rem;
        }

        img{
            height: 7rem;
            width: 10rem;
            object-fit: contain;
        }

        p{
            margin-top: 2rem;
            font-weight: bold;
        }

        .tipoPeçaRadio {
            display: flex;
            width: 100%;
            //justify-content: space-around;

            div {
                display: flex;
                justify-content: center;
                //align-items: center;
                font-size: 120%;
                margin-right: 8%;

                input {
                    margin-right: 5%;
                    margin-top: 10%;
                }
            }
        }

        #corTematicaInput{
            width: 40%;
            height: 5em;
        }
    }

    .modalFooter{ 

        .cancelBtn{
            background-color: $warningColor;
        }
    }
}
