@import './config.scss';

.container-all{

    .icones{
        width: 23px;
        margin-right: 10px;
        margin-bottom: 0px;
       
    }
    a{
        color: $primaryColor;
        font-weight: bold;
    }

    .logo-img{
        width: 100%;
        height: 100%;
        background-color: $primaryColor;
        padding-top: 12%;
        
        .logo{
            display: block;
            margin: auto;
            padding-bottom: 5%;
            width: 50%;
            
        }

    }
    .container-cadastro{

        .alert{
            color: $lightGreenColor;
            background-color: $darkGreenColor;
            width: auto;
            margin-top: 5%;
            border: none;

            img{
                width: 5%;
                margin-right: 2%;
            }

        }

        form{
            padding-bottom: 30%;
            padding-top: 7%;
        }

        a{
            color: $primaryColor;
            font-weight: bold;
        }

        .link-login{
            text-align: center;
            margin-bottom: 0;
        }


        button{
            width: 80%;
            margin-left: 10%;
            height: 60px;
            margin-top: 15px;
            background-color: $primaryColor;
            border-color: $primaryColor;
            color: white;
            border-radius: 12px;
            font-size: 20px;
        }

        .input{
            display: flex;
            flex-direction: row;
            padding: 8px;
            padding-left: 10%;
            padding-right: 10%;


            input{
                margin-bottom: 0px;
                font-size: 16px;
                display: flex;
                align-items: center;
                letter-spacing: 0.03em;
                color: $primaryColor;
                width: 100%;
                border-radius: 0%;
                padding: 0px;

                border-top-width: 0px;
                border-right-width: 0px;
                border-left-width: 0px;

                border-bottom: solid black 3px;

                outline:none;
                
                &:focus{
                    border-color: $primaryColor;
                }

                &::placeholder{
                    color: $lightGreyColor;
                }
                

            }

        }
    }

    @media screen and (min-width:990px) {

        .container-all{
            display: flex;
            flex-direction: row;
        }

         .logo-img{
            position: fixed;
            left: 0;
            top: 0%;
            width: auto;
            
            .logo{
                width:75%;
                margin: auto;
                align-items: center;
                padding: 0;
                position: relative;
                top: -10%;
            }
        }

        .container-cadastro{
            align-items: center;
            justify-content: center;

            .input{
                padding-left: 0;
                padding-right: 0;
            }

            button{
                width: 100%;
                margin-left: 0;
            }

            form{
                padding: 80px 40px;
                margin-left: 0%;
                width: 520px;
                position: fixed;
                right: 15%;
                left: 52%;
                top: 15%;
                margin: auto;
                    

            }
        }
    }

}

