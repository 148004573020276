@import './config.scss';

.confirm-buy{

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;

    .skin {
        border: solid 1px #CCC;
        border-radius: 10px;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,0.3);
        margin: 20px;
        margin-left: 18%;
        //width: 185px;
    }

    .modal-body{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .skin img{
        //margin: 15px;
        width: 80%;
    }

    .skinPreco {
        text-align: center;
        margin-bottom: 5%;
    }


    .btn-confirma{
        background-color: $primaryColor;
        color: white;
        border: solid 1px;
        border-radius: 5px;
        font-weight: bold;
        padding: 5px 25px;
        height: 60px;
        margin-right: 10px;
        }

    .btn-cancela{
        background-color: red;
        color: white;
        border: solid 1px;
        border-radius: 5px;
        font-weight: bold;
        padding: 5px 25px;
        height: 60px;
        }
    
    img{
        width: 15%;
    }
}

.bodyLoja {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 0px 100px;
}

.skinsContainer img{
    height: 153.88px;
    width: 153.88px;
}

.bodyLoja h2{
    margin-top: 20px;
    font-weight: bold;
}

.skinsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.skin {
    border: solid 1px #CCC;
    border-radius: 10px;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.3);
    margin: 10px 20px;
    width: 185px;
}

.skinInfo h4{
    text-align: center;
}

.skin img{
    margin: 15px;
}

.skinPreco {
    text-align: center;
}

.skin button{
    margin: 10px 30px;
    background-color: $primaryColor;
    border: solid 1px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    padding: 5px 25px;
}

